<template>
  <el-row :gutter='10'>
    <el-col :lg="12" :md="24" :sm="24" :xl="12" :xs="24" class="ul">
      <div class="dialogCard">
        <div class="subBox">
          <div class="title">实时访问分析</div>
          <div class="subTitle">更新时间：{{nowTime}}</div>
        </div>

        <el-row>
          <el-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" class="ul" v-for="(item, index) in interactionData" :key="index">
            <p class='smTitle'>{{item.name}}</p>
            <div v-if="loading">加载中...</div>
            <template v-else>
              <vab-count :decimals="item.countConfig.decimals" :duration="item.countConfig.duration" :end-val="item.countConfig.endVal" :prefix="item.countConfig.prefix" :separator="item.countConfig.separator" :start-val="item.countConfig.startVal"
                style="font-size:26px;color:#1D2129;font-weight:bold;" />
              <span style="font-size:16px;color:#4e5969">{{item.countConfig.suffix}}</span>
            </template>
          </el-col>
        </el-row>
      </div>
    </el-col>
    <el-col :lg="12" :md="24" :sm="24" :xl="12" :xs="24" class="ul">
      <div class="dialogCard">
        <div class="subBox">
          <div class="title">内容配置</div>
          <div class="subTitle">更新时间：{{nowTime}}</div>
        </div>

        <el-row>
          <el-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" class="ul" v-for="(item, index) in contentData" :key="index">
            <p class='smTitle'>{{item.name}}</p>
            <div v-if="loading">加载中...</div>
            <template v-else>
              <vab-count :decimals="item.countConfig.decimals" :duration="item.countConfig.duration" :end-val="item.countConfig.endVal" :prefix="item.countConfig.prefix" :separator="item.countConfig.separator" :start-val="item.countConfig.startVal"
                style="font-size:26px;color:#1D2129;font-weight:bold;" />
              <span style="font-size:16px;color:#4e5969">{{item.countConfig.suffix}}</span>
            </template>
          </el-col>
        </el-row>
      </div>
    </el-col>

  </el-row>

</template>

<script>
import { defineComponent, reactive, toRefs, watch } from 'vue'
import _ from 'lodash'
import VabChart from '@/extra/VabChart'
import VabCount from '@/extra/VabCount'

export default defineComponent({
  components: {
    VabChart,
    VabCount,
  },
  props: {
    aiData: {
      type: Object,
      default: {},
    },
    nowTime: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    watch(
      () => props.aiData,
      (newVal, oldVal) => {
        state.interactionData[0].countConfig.endVal =
          newVal.meetingAiAgentTodayNum
        state.interactionData[1].countConfig.endVal =
          newVal.meetingAiAgentTodayTimes
        state.interactionData[2].countConfig.endVal =
          newVal.meetingAiAgentSessionNum
        state.contentData[0].countConfig.endVal = newVal.aiAgentStudyLibFileNum
        state.contentData[1].countConfig.endVal = newVal.aiAgentStudyLibLinkNum
        state.contentData[2].countConfig.endVal = newVal.aiAgentQANum
        setTimeout(() => {
          state.loading = false
        }, 100)
      }
    )
    const state = reactive({
      loading: true,
      interactionData: [
        {
          countConfig: {
            startVal: 0,
            endVal: 0,
            decimals: 0,
            prefix: '',
            suffix: '人',
            separator: ',',
            duration: 3000,
          },
          name: '访问人数',
          colCount: 8,
        },
        {
          countConfig: {
            startVal: 0,
            endVal: 0,
            decimals: 0,
            prefix: '',
            suffix: '次',
            separator: ',',
            duration: 3000,
          },
          name: '访问次数',
          colCount: 8,
        },
        {
          countConfig: {
            startVal: 0,
            endVal: 0,
            decimals: 0,
            prefix: '',
            suffix: '次',
            separator: ',',
            duration: 3000,
          },
          name: '会话次数',
          colCount: 8,
        },
      ],
      contentData: [
        {
          countConfig: {
            startVal: 0,
            endVal: 0,
            decimals: 0,
            prefix: '',
            suffix: '份',
            separator: ',',
            duration: 3000,
          },
          name: '知识库文件数',
          colCount: 8,
        },
        {
          countConfig: {
            startVal: 0,
            endVal: 0,
            decimals: 0,
            prefix: '',
            suffix: '个',
            separator: ',',
            duration: 3000,
          },
          name: '知识库链接数',
          colCount: 8,
        },
        {
          countConfig: {
            startVal: 0,
            endVal: 0,
            decimals: 0,
            prefix: '',
            suffix: '个',
            separator: ',',
            duration: 3000,
          },
          name: '预设问答数量',
          colCount: 8,
        },
      ],
    })
    return {
      ...toRefs(state),
    }
  },
})
</script>




<style lang="scss" scoped>
.dialogCard {
  width: 100%;
  min-height: 174px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 10px;
  .subBox {
    .title {
      font-weight: 500;
      font-size: 16px;
      color: #1d2129;
      margin-bottom: 4px;
    }
    .subTitle {
      font-size: 12px;
      color: #4e5969;
    }
  }

  .ul {
    margin-top: 15px;
    .smTitle {
      font-size: 16px;
      color: #1d2129;
    }
  }
}
</style>